import React, { Component } from 'react'
import styled from 'styled-components'
import sourceeasydata from '../../sourceeasydata.json'
import HeroImage from '../components/heroImage'
import HeroCaseStudy from '../components/heroCaseStudy'
import TextBlockCentered from '../components/textBlockCentered'
import IntroCaseStudy2 from '../components/introCaseStudy-2col.js'
import FooterCaseStudyBack from '../components/footerCaseStudyBack';

const SectionHeroCaseStudy = styled.div`
width: 100%;
`
const SectionIntroCaseStudy = styled.div`
width: 100%;
`
const SectionTextBlockCentered = styled.div`
width: 100%;
`
import Layout from "../components/layout"

export default class Sourceeasy extends Component {
	render () {
		return (
		<Layout>
			<div>
				<SectionIntroCaseStudy>
					{sourceeasydata.heroCaseStudy.map(cell => (
						<HeroCaseStudy title={cell.title} 
						subtitle={cell.subtitle}
						image={cell.image}/>
					))}
				</SectionIntroCaseStudy>
				<SectionIntroCaseStudy>
					{sourceeasydata.introCaseStudy2.map(cell => (
						<IntroCaseStudy2 title1={cell.title1} 
						description1={cell.description1} title2={cell.title2} 
						description2={cell.description2}
						/>
					))}
				</SectionIntroCaseStudy>
				<SectionTextBlockCentered>
					{sourceeasydata.textBlockCentered.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3}  />
					))}
				</SectionTextBlockCentered>
				<FooterCaseStudyBack />
			</div>
		</Layout>
		)
	}
}